<template>
  <div class="d-flex ga-4 align-center pr-2" :style="`min-height: ${height}`">
    <v-list-item :prepend-icon="icon" class="pr-0">
      <template #title>
        <slot name="title">{{ title }}</slot>
      </template>

      <template #subtitle>
        <slot name="subtitle">{{ subtitle }}</slot>
      </template>
    </v-list-item>
    <v-divider />
    <slot name="append" />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    height?: string
    icon?: string
    title?: string
    subtitle?: string
  }>(),
  {
    height: "48px",
    title: undefined,
    icon: undefined,
    subtitle: undefined,
  },
)
const { title, subtitle, icon, height } = toRefs(props)
</script>
